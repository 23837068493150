import { SvgIconProps } from "@mui/material";
import { Link, Routes, Route, useLocation, Navigate, useNavigate, Outlet, BrowserRouter, useRoutes, RouteObject } from "react-router-dom";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Collections, DeliveryDining, Headphones, Home, Map, NotificationsActive } from "@mui/icons-material";
import { Box, maxHeight } from "@mui/system";
import Startseite from "../Sites/Startseite";
import Leistungsspektrum from "../Sites/Leistungsspektrum";
import Naturheilkunde from "../Sites/Naturheilkunde";
import AboutUs from "../Sites/AboutUs";
import SprechzeitenAnfahrt from "../Sites/SprechzeitenAnfahrt";
import Impressum from "../Sites/Impressum";
import Datenschutz from "../Sites/Datenschutz";
import Rezeptbestellung from "../Sites/Rezeptbestellung";
// import Leistungsspektrum from "../Sites/Leistungsspektrum";
// type RouteObjectApp = Omit<RouteObject, 'children'>  & {icon?: string} & {name?:string} & {children?:RouteObjectApp[]}

interface IRouteObjectApp {
  element: React.ReactElement | null,
  path?: string,
  name?: string,
  needsAuthorization?: boolean,
  isTopRoute?: boolean,
  icon?: SvgIconProps,
  children?: IRouteObjectApp[],
  maxWidthOfRoute?:number
}

export const routingOfApp: IRouteObjectApp[] =
  [
    {
      isTopRoute: true,
      element: <Outlet />,
      children: [
        {
          path: "/",
          element: <Startseite></Startseite>,
          // icon: <Home></Home>,
          name: "Startseite"
        },
        {
          path: "/Leistungsspektrum",
          element: <Leistungsspektrum></Leistungsspektrum>,
          // icon: <Home></Home>,
          name: "Leistungsspektrum"
        },
        {
          path: "/Naturheilkunde-und-Akupunktur",
          element: <Naturheilkunde></Naturheilkunde>,
          // icon: <Home></Home>,
          name: "NATURHEILKUNDE & AKUPUNKTUR"
        },
        {
          path: "/UeberUns",
          element: <AboutUs></AboutUs>,
          // icon: <Home></Home>,
          name: "Über Uns"
        },
        {
          path: "/Sprechzeiten-und-Anfahrt",
          element: <SprechzeitenAnfahrt></SprechzeitenAnfahrt>,
          // icon: <Home></Home>,
          name: "Sprechzeiten & Anfahrt"
        },
        // {
        //   path: "/Rezepte-und-Ueberweisungen",
        //   element: <Rezeptbestellung></Rezeptbestellung>,
        //   // icon: <Home></Home>,
        //   name: "Rezepte & Überweisungen",
        //   maxWidthOfRoute:170
        // },
        
        // {
        //   path: "/mediaplayer",
        //   element: <MediaPlayer></MediaPlayer>,
        //   icon: <Headphones />,
        //   name: "MediaPlayer"
        // },
        // {
        //   path: "/notifications",
        //   element: <NotificationSite />,
        //   icon: <NotificationsActive />,
        //   name: "Notifications"
        // }
        // ,
        // {
        //   path: "/images",
        //   element: <CachedImageViewer />,
        //   icon: <Collections />,
        //   name: "CachedImageViewer"
        // }
        // ,
        // {
        //   path: "/maps",
        //   element: <RoutePlanning />,

        //   icon: <Map />,
        //   name: "LeafletMap"
        // },
        // {
        //   path:"/delivery",
        //   element:<DeliveryPlanningContainer/>,
        //   icon:<DeliveryDining/>,
        //   name:"Delivery"
        // }
      ]
    },
    // {
    //   isTopRoute: true,
    //   needsAuthorization: true,
    //   element: <RequireAuth>
    //     <Outlet />
    //   </RequireAuth>,
    //   children: [{
    //     path: "/LoggedIn",
    //     element: <StartPage></StartPage>,
    //     icon: <InboxIcon></InboxIcon>,
    //     name: "Startseite LoggedIn"
    //   }
    //   ]
    // },
    {
      isTopRoute: true,
      element: <Outlet />,
      children: [
        {
          path: "/Impressum",
          element: <Impressum></Impressum>,
          // icon: <Home></Home>,
          name: "Impressum"
        },
        // {
        {
          path: "/Datenschutz",
          element: <Datenschutz></Datenschutz>,
          // icon: <Home></Home>,
          name: "Datenschutz"
        },
        
        // {
        //   path: "/mediaplayer",
        //   element: <MediaPlayer></MediaPlayer>,
        //   icon: <Headphones />,
        //   name: "MediaPlayer"
        // },
        // {
        //   path: "/notifications",
        //   element: <NotificationSite />,
        //   icon: <NotificationsActive />,
        //   name: "Notifications"
        // }
      ]
    },
    {
      isTopRoute: true,
      element: <Outlet />,
      children: [
        {
          path: "/leistungsspektrum.html",
          element: <Leistungsspektrum></Leistungsspektrum>,
          // icon: <Home></Home>,
          name: "Leistungsspektrum"
        },
        {
          path: "/naturheilkunde.html",
          element: <Naturheilkunde></Naturheilkunde>,
          // icon: <Home></Home>,
          name: "Naturheilkunde"
        },
        {
          path: "/ueberuns.html",
          element: <AboutUs></AboutUs>,
          // icon: <Home></Home>,
          name: "Über uns"
        },
        {
          path: "/sprechzeiten.html",
          element: <SprechzeitenAnfahrt></SprechzeitenAnfahrt>,
          // icon: <Home></Home>,
          name: "Sprechzeiten und Anfahrt"
        },
        // {
        {
          path: "/datenschutz.html",
          element: <Datenschutz></Datenschutz>,
          // icon: <Home></Home>,
          name: "Datenschutz"
        },
        {
          path: "/impressum.html",
          element: <Impressum></Impressum>,
          // icon: <Home></Home>,
          name: "Impressum"
        },
        
      ]
    },
  ];

export const routesOfApp: RouteObject[] = routingOfApp.map((el: IRouteObjectApp) => {

  let routeObj: RouteObject = {

  }
  if (el.element) {
    routeObj.element = el.element
  }
  if (el.path) {
    routeObj.path = el.path
  }
  if (el.children) {
    routeObj.children = el.children
  }


  return routeObj
}

);


